var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"titleBox"},[_vm._v("院校对比")]),(_vm.tableList.length > 0)?_c('div',{staticClass:"bodyBox"},[(_vm.tableList.length > 1)?_c('div',{staticClass:"flagBox"},[_c('el-switch',{attrs:{"active-text":"隐藏相同项","active-color":"#13ce66","inactive-color":"#ff4949"},on:{"change":_vm.switchChange},model:{value:(_vm.switchvalue),callback:function ($$v) {_vm.switchvalue=$$v},expression:"switchvalue"}})],1):_vm._e(),_c('div',{staticClass:"tableBox"},[(_vm.fixedHeader)?_c('div',{staticClass:"tableBody fixed-header"},[_c('div',{staticClass:"rowTitle"}),_c('div',{staticClass:"contentBox"},[_c('table',{staticClass:"tablelist",attrs:{"cellspacing":"0"}},[_c('tr',[_vm._m(0),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,staticClass:"namebox tdlast",style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[_c('div',[_vm._v(" "+_vm._s(item.SchoolName)+" ")])])})],2)])])]):_vm._e(),(
          _vm.assessmentList.findIndex(function (item) { return item.showFlag === true; }) !== -1
        )?_c('div',{staticClass:"tableBody"},[_vm._m(1),_c('div',{ref:"assessment",staticClass:"contentBox"},[_c('table',{staticClass:"tablelist",attrs:{"cellspacing":"0"}},_vm._l((_vm.assessmentList),function(ite,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(ite.showFlag),expression:"ite.showFlag"}],key:index},[_c('td',{class:{ tdlast: index === _vm.assessmentList.length - 1 }},[_c('div',{staticClass:"titleTd"},[_vm._v(_vm._s(ite.name))])]),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,class:{
                  td: index % 2 === 0 && ite.value !== 'SchoolName',
                  namebox: ite.value === 'SchoolName',
                  tdlast: index === _vm.assessmentList.length - 1,
                },style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[_c('div',[(ite.value === 'IsGraduateSchool')?[_vm._v(" "+_vm._s(item[ite.value] === "" ? "" : item[ite.value] === true ? "是" : "否")+" ")]:(
                      ite.value === 'SuperioritySpecialties' ||
                      ite.value === 'SpecialSpecialties'
                    )?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item[ite.value],"placement":"bottom"}},[_c('div',{staticClass:"overtext"},[_vm._v(" "+_vm._s(item[ite.value])+" ")])])]:[_vm._v(" "+_vm._s(item[ite.value])+" ")]],2)])})],2)}),0)])]):_vm._e(),(_vm.teachList.findIndex(function (item) { return item.showFlag === true; }) !== -1)?_c('div',{staticClass:"tableBody rowTitleactiv"},[_vm._m(2),_c('div',{ref:"teachList",staticClass:"contentBox"},[_c('table',{staticClass:"tablelist tablelistActive",attrs:{"cellspacing":"0"}},_vm._l((_vm.teachList),function(ite,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(ite.showFlag),expression:"ite.showFlag"}],key:index},[_c('td',{class:{ tdlast: index === _vm.teachList.length - 1 }},[_c('div',{staticClass:"titleTd"},[_vm._v(_vm._s(ite.name))])]),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,class:{
                  td: index % 2 === 0,
                  tdlast: index === _vm.teachList.length - 1,
                },style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[(ite.value === 'LabotoryList')?_vm._l((item.LabotoryList),function(ite,index){return _c('div',{key:index},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":ite.LabNames,"placement":"bottom"}},[_c('div',{staticClass:"overtext"},[_vm._v(" "+_vm._s(ite.CategoryName + "(" + ite.TotalOfLabs + ");")+" ")])])],1)}):[_c('div',[_vm._v(" "+_vm._s(item[ite.value])+" ")])]],2)})],2)}),0)])]):_vm._e(),(_vm.ratioList.findIndex(function (item) { return item.showFlag === true; }) !== -1)?_c('div',{staticClass:"tableBody rowTitleactiv"},[_vm._m(3),_c('div',{ref:"ratioList",staticClass:"contentBox"},[_c('table',{staticClass:"tablelist tablelistActive",attrs:{"cellspacing":"0"}},_vm._l((_vm.ratioList),function(ite,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(ite.showFlag),expression:"ite.showFlag"}],key:index},[_c('td',{class:{ tdlast: index === _vm.ratioList.length - 1 }},[_c('div',{staticClass:"titleTd"},[_vm._v(_vm._s(ite.name))])]),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,class:{
                  td: index % 2 === 0,
                  tdlast: index === _vm.ratioList.length - 1,
                },style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[_c('div',[(ite.value === 'PercentOfMale')?[_vm._v(" "+_vm._s(item[ite.value] && item.PercentOfFemale && item[ite.value] + "%(男)：" + item.PercentOfFemale + "%(女)")+" ")]:[_vm._v(" "+_vm._s(item[ite.value] && item[ite.value] + "%")+" ")]],2)])})],2)}),0)])]):_vm._e(),(_vm.schoolList.findIndex(function (item) { return item.showFlag === true; }) !== -1)?_c('div',{staticClass:"tableBody rowTitleactiv"},[_vm._m(4),_c('div',{ref:"schoolList",staticClass:"contentBox"},[_c('table',{staticClass:"tablelist tablelistActive",attrs:{"cellspacing":"0"}},_vm._l((_vm.schoolList),function(ite,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(ite.showFlag),expression:"ite.showFlag"}],key:index,ref:"tableTr",refInFor:true},[_c('td',{class:{ tdlast: index === _vm.schoolList.length - 1 }},[_c('div',{staticClass:"titleTd"},[_vm._v(_vm._s(ite.RankingTypeName))])]),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,class:{
                  td: index % 2 === 1,
                  tdlast: index === _vm.schoolList.length - 1,
                },style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[_c('div',[_vm._v(" "+_vm._s(item.RankingList[ item.RankingList.findIndex( function (obj) { return obj.RankingTypeName === ite.RankingTypeName; } ) ] && item.RankingList[ item.RankingList.findIndex( function (obj) { return obj.RankingTypeName === ite.RankingTypeName; } ) ].RankingOfInternal)+" ")])])})],2)}),0)])]):_vm._e(),(_vm.IndicateList.findIndex(function (item) { return item.showFlag === true; }) !== -1)?_c('div',{staticClass:"tableBody rowTitleactiv"},[_vm._m(5),_c('div',{ref:"indicateHeight",staticClass:"contentBox"},[_c('table',{staticClass:"tablelist tablelistActive",attrs:{"cellspacing":"0"}},_vm._l((_vm.IndicateList),function(ite,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(ite.showFlag),expression:"ite.showFlag"}],key:index,ref:"tableTr",refInFor:true},[_c('td',{class:{ tdlast: index === _vm.IndicateList.length - 1 }},[_c('div',{staticClass:"titleTd"},[_vm._v(_vm._s(ite.IndicatorName))])]),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,class:{
                  td: index % 2 === 1,
                  tdlast: index === _vm.IndicateList.length - 1,
                },style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[_c('div',[_vm._v(" "+_vm._s(item.IndicateList[ item.IndicateList.findIndex( function (obj) { return obj.IndicatorName === ite.IndicatorName; } ) ] && item.IndicateList[ item.IndicateList.findIndex( function (obj) { return obj.IndicatorName === ite.IndicatorName; } ) ].Score)+" ")])])})],2)}),0)])]):_vm._e(),(
          _vm.SpecialtyAssessmentList.findIndex(
            function (item) { return item.showFlag === true; }
          ) !== -1
        )?_c('div',{staticClass:"tableBody rowTitleactiv"},[_vm._m(6),_c('div',{ref:"SpecialtyAssessmentList",staticClass:"contentBox"},[_c('table',{staticClass:"tablelist tablelistActive",attrs:{"cellspacing":"0"}},_vm._l((_vm.SpecialtyAssessmentList),function(ite,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(ite.showFlag),expression:"ite.showFlag"}],key:index,ref:"tableTr",refInFor:true},[_c('td',{class:{
                  tdlast: index === _vm.SpecialtyAssessmentList.length - 1,
                }},[_c('div',{staticClass:"titleTd"},[_vm._v(_vm._s(ite.ResultLevelStr))])]),_vm._l((_vm.tableList),function(item){return _c('td',{key:item.ID,class:{
                  td: index % 2 === 1,
                  tdlast: index === _vm.SpecialtyAssessmentList.length - 1,
                },style:({ width: (1250 - 210) / _vm.tableList.length + 'px' })},[_c('div',[_vm._v(" "+_vm._s(item.SpecialtyAssessmentList[ item.SpecialtyAssessmentList.findIndex( function (obj) { return obj.ResultLevelStr === ite.ResultLevelStr; } ) ] && (item.SpecialtyAssessmentList[ item.SpecialtyAssessmentList.findIndex( function (obj) { return obj.ResultLevelStr === ite.ResultLevelStr; } ) ].ResultCount > 0 ? "数量" + item.SpecialtyAssessmentList[ item.SpecialtyAssessmentList.findIndex( function (obj) { return obj.ResultLevelStr === ite.ResultLevelStr; } ) ].ResultCount + ":" : "") + item.SpecialtyAssessmentList[ item.SpecialtyAssessmentList.findIndex( function (obj) { return obj.ResultLevelStr === ite.ResultLevelStr; } ) ].ResultSpecialtyCategoryItem)+" ")])])})],2)}),0)])]):_vm._e()]),_c('div',{staticClass:"btnbox"},[_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返 回")])],1)]):_c('div',{staticStyle:{"text-align":"center","margin-top":"200px"}},[_vm._v("暂无数据")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"tdlast"},[_c('div',{staticClass:"titleTd"},[_vm._v("院校名称")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rowTitle"},[_c('div',{staticClass:"text"},[_vm._v("综合信息")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rowTitle rowTitleactiv"},[_c('div',{staticClass:"text"},[_vm._v("教研实力")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rowTitle"},[_c('div',{staticClass:"text"},[_vm._v("深造情况")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rowTitle"},[_c('div',{staticClass:"text"},[_vm._v("院校排名")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rowTitle rowTitleactiv"},[_c('div',{staticClass:"text"},[_vm._v("综合评级")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rowTitle rowTitleactiv"},[_c('div',{staticClass:"text"},[_vm._v("学科评估")])])}]

export { render, staticRenderFns }