<template>
  <div class="app-container">
    <div class="titleBox">院校对比</div>
    <div class="bodyBox" v-if="tableList.length > 0">
      <div class="flagBox" v-if="tableList.length > 1">
        <el-switch
          v-model="switchvalue"
          active-text="隐藏相同项"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="switchChange"
        ></el-switch>
      </div>
      <div class="tableBox">
        <div class="tableBody fixed-header" v-if="fixedHeader">
          <div class="rowTitle">
            <!-- <div class="text">综合信息</div> -->
          </div>
          <div class="contentBox">
            <table class="tablelist" cellspacing="0">
              <tr>
                <td class="tdlast">
                  <div class="titleTd">院校名称</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  class="namebox tdlast"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <div>
                    {{ item.SchoolName }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="tableBody"
          v-if="
            assessmentList.findIndex((item) => item.showFlag === true) !== -1
          "
        >
          <div class="rowTitle">
            <div class="text">综合信息</div>
          </div>
          <div class="contentBox" ref="assessment">
            <table class="tablelist" cellspacing="0">
              <tr
                v-for="(ite, index) in assessmentList"
                :key="index"
                v-show="ite.showFlag"
              >
                <td :class="{ tdlast: index === assessmentList.length - 1 }">
                  <div class="titleTd">{{ ite.name }}</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  :class="{
                    td: index % 2 === 0 && ite.value !== 'SchoolName',
                    namebox: ite.value === 'SchoolName',
                    tdlast: index === assessmentList.length - 1,
                  }"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <div>
                    <template v-if="ite.value === 'IsGraduateSchool'">
                      {{
                        item[ite.value] === ""
                          ? ""
                          : item[ite.value] === true
                          ? "是"
                          : "否"
                      }}
                    </template>
                    <template
                      v-else-if="
                        ite.value === 'SuperioritySpecialties' ||
                        ite.value === 'SpecialSpecialties'
                      "
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item[ite.value]"
                        placement="bottom"
                      >
                        <div class="overtext">
                          {{ item[ite.value] }}
                        </div>
                      </el-tooltip>
                    </template>
                    <template v-else>
                      {{ item[ite.value] }}
                    </template>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="tableBody rowTitleactiv"
          v-if="teachList.findIndex((item) => item.showFlag === true) !== -1"
        >
          <div class="rowTitle rowTitleactiv">
            <div class="text">教研实力</div>
          </div>
          <div class="contentBox" ref="teachList">
            <table class="tablelist tablelistActive" cellspacing="0">
              <tr
                v-for="(ite, index) in teachList"
                :key="index"
                v-show="ite.showFlag"
              >
                <td :class="{ tdlast: index === teachList.length - 1 }">
                  <div class="titleTd">{{ ite.name }}</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  :class="{
                    td: index % 2 === 0,
                    tdlast: index === teachList.length - 1,
                  }"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <template v-if="ite.value === 'LabotoryList'">
                    <div v-for="(ite, index) in item.LabotoryList" :key="index">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="ite.LabNames"
                        placement="bottom"
                      >
                        <div class="overtext">
                          {{ ite.CategoryName + "(" + ite.TotalOfLabs + ");" }}
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      {{ item[ite.value] }}
                    </div>
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="tableBody rowTitleactiv"
          v-if="ratioList.findIndex((item) => item.showFlag === true) !== -1"
        >
          <div class="rowTitle">
            <div class="text">深造情况</div>
          </div>
          <div class="contentBox" ref="ratioList">
            <table class="tablelist tablelistActive" cellspacing="0">
              <tr
                v-for="(ite, index) in ratioList"
                :key="index"
                v-show="ite.showFlag"
              >
                <td :class="{ tdlast: index === ratioList.length - 1 }">
                  <div class="titleTd">{{ ite.name }}</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  :class="{
                    td: index % 2 === 0,
                    tdlast: index === ratioList.length - 1,
                  }"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <div>
                    <template v-if="ite.value === 'PercentOfMale'">
                      {{
                        item[ite.value] &&
                        item.PercentOfFemale &&
                        item[ite.value] +
                          "%(男)：" +
                          item.PercentOfFemale +
                          "%(女)"
                      }}
                    </template>
                    <template v-else>
                      {{ item[ite.value] && item[ite.value] + "%" }}
                    </template>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="tableBody rowTitleactiv"
          v-if="schoolList.findIndex((item) => item.showFlag === true) !== -1"
        >
          <div class="rowTitle">
            <div class="text">院校排名</div>
          </div>
          <div class="contentBox" ref="schoolList">
            <table class="tablelist tablelistActive" cellspacing="0">
              <tr
                ref="tableTr"
                v-for="(ite, index) in schoolList"
                :key="index"
                v-show="ite.showFlag"
              >
                <td :class="{ tdlast: index === schoolList.length - 1 }">
                  <div class="titleTd">{{ ite.RankingTypeName }}</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  :class="{
                    td: index % 2 === 1,
                    tdlast: index === schoolList.length - 1,
                  }"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <div>
                    {{
                      item.RankingList[
                        item.RankingList.findIndex(
                          (obj) => obj.RankingTypeName === ite.RankingTypeName
                        )
                      ] &&
                      item.RankingList[
                        item.RankingList.findIndex(
                          (obj) => obj.RankingTypeName === ite.RankingTypeName
                        )
                      ].RankingOfInternal
                    }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="tableBody rowTitleactiv"
          v-if="IndicateList.findIndex((item) => item.showFlag === true) !== -1"
        >
          <div class="rowTitle rowTitleactiv">
            <div class="text">综合评级</div>
          </div>
          <div class="contentBox" ref="indicateHeight">
            <table class="tablelist tablelistActive" cellspacing="0">
              <tr
                ref="tableTr"
                v-for="(ite, index) in IndicateList"
                :key="index"
                v-show="ite.showFlag"
              >
                <td :class="{ tdlast: index === IndicateList.length - 1 }">
                  <div class="titleTd">{{ ite.IndicatorName }}</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  :class="{
                    td: index % 2 === 1,
                    tdlast: index === IndicateList.length - 1,
                  }"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <div>
                    {{
                      item.IndicateList[
                        item.IndicateList.findIndex(
                          (obj) => obj.IndicatorName === ite.IndicatorName
                        )
                      ] &&
                      item.IndicateList[
                        item.IndicateList.findIndex(
                          (obj) => obj.IndicatorName === ite.IndicatorName
                        )
                      ].Score
                    }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="tableBody rowTitleactiv"
          v-if="
            SpecialtyAssessmentList.findIndex(
              (item) => item.showFlag === true
            ) !== -1
          "
        >
          <div class="rowTitle rowTitleactiv">
            <div class="text">学科评估</div>
          </div>
          <div class="contentBox" ref="SpecialtyAssessmentList">
            <table class="tablelist tablelistActive" cellspacing="0">
              <tr
                ref="tableTr"
                v-for="(ite, index) in SpecialtyAssessmentList"
                :key="index"
                v-show="ite.showFlag"
              >
                <td
                  :class="{
                    tdlast: index === SpecialtyAssessmentList.length - 1,
                  }"
                >
                  <div class="titleTd">{{ ite.ResultLevelStr }}</div>
                </td>
                <td
                  :style="{ width: (1250 - 210) / tableList.length + 'px' }"
                  :class="{
                    td: index % 2 === 1,
                    tdlast: index === SpecialtyAssessmentList.length - 1,
                  }"
                  v-for="item in tableList"
                  :key="item.ID"
                >
                  <div>
                    {{
                      item.SpecialtyAssessmentList[
                        item.SpecialtyAssessmentList.findIndex(
                          (obj) => obj.ResultLevelStr === ite.ResultLevelStr
                        )
                      ] &&
                      (item.SpecialtyAssessmentList[
                        item.SpecialtyAssessmentList.findIndex(
                          (obj) => obj.ResultLevelStr === ite.ResultLevelStr
                        )
                      ].ResultCount > 0
                        ? "数量" +
                          item.SpecialtyAssessmentList[
                            item.SpecialtyAssessmentList.findIndex(
                              (obj) => obj.ResultLevelStr === ite.ResultLevelStr
                            )
                          ].ResultCount +
                          ":"
                        : "") +
                        item.SpecialtyAssessmentList[
                          item.SpecialtyAssessmentList.findIndex(
                            (obj) => obj.ResultLevelStr === ite.ResultLevelStr
                          )
                        ].ResultSpecialtyCategoryItem
                    }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="btnbox">
        <el-button @click="$router.go(-1)" type="primary" size="medium">
          返 回</el-button
        >
      </div>
    </div>
    <div v-else style="text-align: center; margin-top: 200px">暂无数据</div>
  </div>
</template>
<script>
import API from "@/api/config";
export default {
  name: "college-CompareCollege",
  data() {
    return {
      assessmentList: [
        //   综合信息
        {
          value: "SchoolName",
          name: "院校名称",
          showFlag: true,
        },
        {
          value: "CategoryNames",
          name: "院校类别",
          showFlag: true,
        },
        {
          value: "ProvinceName",
          name: "省份名称",
          showFlag: true,
        },
        {
          value: "CityName",
          name: "城市名称",
          showFlag: true,
        },
        {
          value: "SuperioritySpecialties",
          name: "优势专业",
          showFlag: true,
        },
        {
          value: "SpecialSpecialties",
          name: "特色专业",
          showFlag: true,
        },
        {
          value: "EducationLevelStr",
          name: "教育等级",
          showFlag: true,
        },
        {
          value: "NatureStr",
          name: "院校属性",
          showFlag: true,
        },
        // {
        //   value: "IsCooperrationWithAbroadStr",
        //   name: "是否中外合作",
        // },
        {
          value: "ChargeDept",
          name: "主管部门",
          showFlag: true,
        },
        {
          value: "TagNames",
          name: "院校标签",
          showFlag: true,
        },
        {
          value: "EstablishDate",
          name: "创办日期",
          showFlag: true,
        },
        {
          value: "IsGraduateSchool",
          name: "是否开设研究生院",
          showFlag: true,
        },
      ],
      teachList: [
        // 教研实力
        {
          value: "TotalOfTeachers",
          name: "教师总数",
          showFlag: true,
        },
        {
          value: "LabotoryList",
          name: "实验室与研究机构",
          showFlag: true,
        },
      ],
      ratioList: [
        {
          value: "PercentOfMale",
          name: "学生男女占比",
          showFlag: true,
        },
        // {
        //   value: "PercentOfFemale",
        //   name: "学生女性占比",
        // showFlag:true
        // },
        {
          value: "PercentOfPostgraduate",
          name: "读研比例",
          showFlag: true,
        },
        {
          value: "PercentOfGoAbroad",
          name: "出国留学比例",
          showFlag: true,
        },
      ],
      schoolList: [],
      IndicateList: [],
      SpecialtyAssessmentList: [],
      tableList: [],
      switchvalue: false,
      fixedHeader: false,
      LabotoryListFlag: true,
    };
  },
  mounted() {
    this.getTableList();
    window.addEventListener("scroll", this.scroll);
  },
  methods: {
    scroll(e) {
      if (this.headerType === 0) return;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const onHeight = 246;
      if (scrollTop > onHeight) {
        this.fixedHeader = true;
      } else {
        this.fixedHeader = false;
      }
    },
    getTableList() {
      const SchoolIds = this.$ls.get("validContrastValue");
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (SchoolIds && SchoolIds.length > 0) {
        API.School.CompareCollege({
          SchoolIds: SchoolIds,
        })
          .then((res) => {
            this.tableList = res.data;
            this.schoolList = this.tableList[0].RankingList.map((item) => {
              item.showFlag = true;
              return item;
            });
            this.IndicateList = this.tableList[0].IndicateList.map((item) => {
              item.showFlag = true;
              return item;
            });
            this.SpecialtyAssessmentList =
              this.tableList[0].SpecialtyAssessmentList.map((item) => {
                item.showFlag = true;
                return item;
              });
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      } else {
        loading.close();
      }
    },
    // 隐藏相同数据
    switchChange(val) {
      this.setswitchFn(this.assessmentList, val);
      this.setswitchTeach(this.teachList, val);
      this.setswitchFn(this.ratioList, val);
      this.setswitchListFn(this.schoolList, val, 1);
      this.setswitchListFn(this.IndicateList, val, 2);
      this.setswitchListFn(this.SpecialtyAssessmentList, val, 3);
    },
    setswitchTeach(arr, val) {
      arr.forEach((item) => {
        let flog = 0;

        if (val) {
          if (item.value === "LabotoryList") {
            let value = "";
            this.tableList[0][item.value].forEach((lis) => {
              value = value + lis.CategoryName + lis.TotalOfLabs + lis.LabNames;
            });
            this.tableList.forEach((ite, index) => {
              let itevalue = "";
              ite[item.value].forEach((lis) => {
                itevalue =
                  itevalue + lis.CategoryName + lis.TotalOfLabs + lis.LabNames;
              });
              if (value === itevalue) {
                flog++;
              }
            });
          } else {
            this.tableList.forEach((ite, index) => {
              if (this.tableList[0][item.value] === ite[item.value]) {
                flog++;
              }
            });
          }
        }
        if (flog === this.tableList.length) {
          item.showFlag = false;
        } else {
          item.showFlag = true;
        }
      });
    },
    setswitchListFn(arr, val, num) {
      arr.forEach((item) => {
        let flog = 0;
        if (val) {
          this.tableList.forEach((ite) => {
            if (num === 1) {
              ite.RankingList.forEach((ran) => {
                if (
                  item.RankingTypeName === ran.RankingTypeName &&
                  item.RankingOfInternal === ran.RankingOfInternal
                ) {
                  flog++;
                }
              });
            } else if (num === 2) {
              ite.IndicateList.forEach((ran) => {
                if (
                  item.IndicatorName === ran.IndicatorName &&
                  item.Score === ran.Score
                ) {
                  flog++;
                }
              });
            } else if (num === 3) {
              ite.SpecialtyAssessmentList.forEach((ran) => {
                if (
                  item.ResultSpecialtyCategoryItem + item.ResultCount ===
                    ran.ResultSpecialtyCategoryItem + ran.ResultCount &&
                  item.ResultLevelStr === ran.ResultLevelStr
                ) {
                  flog++;
                }
              });
            }
          });
        }
        if (flog === this.tableList.length) {
          item.showFlag = false;
        } else {
          item.showFlag = true;
        }
      });
    },
    setswitchFn(arr, val) {
      arr.forEach((item) => {
        let flog = 0;
        if (val) {
          this.tableList.forEach((ite, index) => {
            if (this.tableList[0][item.value] === ite[item.value]) {
              flog++;
            }
          });
        }
        if (flog === this.tableList.length) {
          item.showFlag = false;
        } else {
          item.showFlag = true;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
// @import "~@/styles/base.scss";
.titleBox {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
  font-weight: bold;
}
.bodyBox {
  width: 100%;
  margin-bottom: 50px;
  color: #555;
  .flagBox {
    text-align: right;
    margin: 10px 0;
  }
  .tableBox {
    width: 100%;
    background: #fff;
  }
  .tableBody {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    .rowTitle {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid #ddd;
      height: 100%;
      .text {
        width: 20px;
        text-align: center;
      }
    }
    .contentBox {
      width: calc(100% - 60px);
    }
    .rowTitleactiv {
      border-top: none;
    }
    .tablelist {
      width: 100%;
      // border-top: 1px solid #ddd;
      td {
        height: 56px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        border-left: 1px solid #ddd;
        // border-right: 1px solid #ddd;
      }
      .tdlast {
        border-bottom: none;
      }
      .td {
        background: #eee;
      }
      .titleTd {
        width: 150px;
      }
    }
    .tablelistActive {
      border-top: none;
    }
  }
  .fixed-header {
    position: fixed;
    top: 0;
    width: 1250px;
    // right: calc((100% - 1250px) / 2);
    z-index: 2000;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
    left: calc((100% - 1250px) / 2);
    transition: width 0.28s;
    height: 56px;
    background: #fff;
  }

  .rowTitleactiv {
    border-top: none;
  }
  .namebox {
    background: rgba(var(--themecolor),0.75);
    color: #fff;
  }
}
.btnbox {
  margin: 40px auto;
  width: 100px;
}
.overtext {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
// .app-container{
//   position: relative;
// }
</style>
<style lang="less">
.app-container .btnbox .el-button--primary{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.app-container .btnbox .el-button--primary:focus, .app-container .btnbox .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
</style>